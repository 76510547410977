import { scrollLock } from "@magnit/core/src/helpers/scrollLock";

export enum MODALS {
  CatalogFilters = "catalog-filters",
  Share = "share",
  Datepicker = "Datepicker",
  CertificateOrder = "certificate-order",
  CertificatesOrderSuccess = "certificates-order-success",
  // ecom begin
  ServiceNotification = "service-notification",
  PromocodeConditions = "promocode-conditions",
  PromocodeEnter = "promocode-enter",
  ReplacementStrategy = "replacement-strategy",
  OrderContacts = "order-contacts",
  ShipmentTime = "shipment-time",
  DeleteCart = "delete-cart",
  BonusInfo = "bonus-info",
  OrderCancel = "order-cancel",
  ReorderError = "reorder-error",
}

export const useModalsStore = defineStore("modals", () => {
  const route = useRoute();
  const openedModals = ref<string[]>([]);

  const getActive = (): string | null =>
    openedModals.value.length > 0 ? openedModals.value[0] : null;

  const isOpen = (modal: MODALS | string): boolean => openedModals.value.includes(modal);

  const open = (name: string, clearable?: boolean, manual?: boolean) => {
    if (clearable) {
      openedModals.value = [name];
    } else {
      openedModals.value.unshift(name);
    }
    if (!manual) {
      scrollLock.enable();
    }
  };

  const close = (name: string) => {
    openedModals.value = openedModals.value.filter((n) => n !== name);

    if (!openedModals.value.length) {
      scrollLock.disable();
    }
  };

  const closeAll = () => {
    openedModals.value = [];
    scrollLock.disable();
  };

  watch(() => route.fullPath, closeAll);

  return {
    getActive,
    open,
    close,
    closeAll,
    isOpen,
  };
});
